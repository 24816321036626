<template>
  <!-- 报表 -->
  <el-dialog class="form" :visible.sync="visible" top="7vh">
    <ul class="cu-table">
      <li class="cu-tr">
        <span class="cu-td h1">员工课件学习记录</span>
      </li>

      <li class="cu-tr">
        <span class="cu-td h2 text-left" style="text-align: left"
          >日期：{{ nowDate }}</span
        >
      </li>

      <div class="user-info">
        <li class="cu-tr">
          <span class="cu-td grow1">姓名</span>
          <span class="cu-td grow2">{{ employee.name }}</span>
          <span class="cu-td grow1">性别</span>
          <span class="cu-td grow2">{{ employee.sex }}</span>
          <span class="cu-td grow1"></span>
        </li>

        <li class="cu-tr">
          <span class="cu-td grow1">工号</span>
          <span class="cu-td grow2">{{ employee.number }}</span>
          <span class="cu-td grow1">岗位</span>
          <span class="cu-td grow2">{{ employee.gangwei }}</span>
          <span class="cu-td grow1"></span>
        </li>

        <li class="cu-tr">
          <span class="cu-td grow1">身份证</span>
          <span class="cu-td grow2">{{ employee.shenfenzheng }}</span>
          <span class="cu-td grow1">入职时间</span>
          <span class="cu-td grow2">{{ employee.ruzhiDate }}</span>
          <span class="cu-td grow1"></span>
        </li>

        <li class="cu-tr">
          <span class="cu-td grow1">电话</span>
          <span class="cu-td grow2">{{ employee.tel }}</span>
          <span class="cu-td grow1">资质名称</span>
          <span class="cu-td grow2">{{ employee.zizhiName }}</span>
          <span class="cu-td grow1"></span>
        </li>

        <li class="cu-tr">
          <span class="cu-td grow1">资质证号</span>
          <span class="cu-td grow2">{{ employee.zizhiNumber }}</span>
          <span class="cu-td grow1">资质有效期</span>
          <span class="cu-td grow2">{{ employee.zizhiValidityDate }}</span>
          <span class="cu-td grow1"></span>
        </li>

        <div class="user-img">
          <el-image
            class="img"
            :src="$http.common.pic + employee.headerPic"
            :preview-src-list="[$http.common.pic + employee.headerPic]"
          />
        </div>
      </div>

      <li class="cu-tr">
        <span class="cu-td grow7"></span>
      </li>

      <li class="cu-tr">
        <span class="cu-td grow1">地址</span>
        <span class="cu-td grow6">{{ employee.address }}</span>
      </li>

      <li class="cu-tr">
        <span class="cu-td grow7 h1">课件培训记录</span>
      </li>

      <li class="cu-tr">
        <span class="cu-td grow1">序号</span>
        <span class="cu-td grow2">课件标题</span>
        <span class="cu-td grow1">岗位</span>
        <span class="cu-td grow1">月份</span>
        <span class="cu-td grow1">培训学时</span>
        <span class="cu-td grow1">完成学时</span>
      </li>

      <template v-for="(item, index) in coursewares">
        <div class="test-list" :key="item.id">
          <li class="cu-tr h3">
            <span class="cu-td grow1">{{ index + 1 }}</span>
            <span class="cu-td grow2">{{ item.title }}</span>
            <span class="cu-td grow1">{{ employee.gangwei }}</span>
            <span class="cu-td grow1">{{ item.studyDate }}</span>
            <span class="cu-td grow1">{{ item.studyDuration / 60 }}分钟</span>
            <span class="cu-td grow1"
              >{{
                item.coursewareLog
                  ? Math.floor(item.coursewareLog.studyDuration / 60)
                  : 0
              }}分钟</span
            >
          </li>

          <template
            v-if="
              item.coursewareLog && item.coursewareLog.pics_item.length != 0
            "
          >
            <div
              class="children"
              v-for="(child, cIndex) in item.coursewareLog.pics_item"
              :key="cIndex"
            >
              <li class="cu-tr">
                <span class="cu-td grow1">学习过程中的相关照片</span>

                <span
                  class="cu-td grow1"
                  v-for="(ele, eindex) in child"
                  :key="eindex"
                >
                  <el-image
                    v-if="ele.pic"
                    class="pic"
                    :src="$http.common.pic + ele.pic"
                    :preview-src-list="[$http.common.pic + ele.pic]"
                  />
                </span>
              </li>

              <li class="cu-tr">
                <span class="cu-td grow1">日期</span>
                <span
                  class="cu-td grow1"
                  v-for="(ele, eindex) in child"
                  :key="eindex"
                  >{{ ele.picDate }}</span
                >
              </li>
            </div>
          </template>
        </div>
      </template>
    </ul>
  </el-dialog>
</template>

<script>
import { getDate } from "@/utils/index.js";
export default {
  data() {
    return {
      visible: false,
      employee: {},
      coursewares: {},
      picUrl: this.$http.common.pic,
      nowDate: "",
    };
  },
  methods: {
    // 组件初始化
    init(row, formDF) {
      this.nowDate = getDate({ symbol: ["年", "月", "日"] });

      this.visible = true;

      this.employee = {};
      this.coursewares = {};
      this.dataForm = {
        ...formDF,
        eid: row.id,
      };

      this.getLookEmployeeInfo();
    },

    // 查看员工个人信息(包含课件学习记录)
    getLookEmployeeInfo() {
      this.$http.employee.lookEmployeeInfo(this.dataForm).then((res) => {
        if (res.code != 0 || !res.data) return this.initFormInfo();

        this.initFormInfo(res.data.employee, res.data.coursewares);
      });
    },

    // 格式化表单信息
    initFormInfo(employee, coursewares) {
      // 判断是否存在学习记录
      if (!coursewares && coursewares.length == 0) {
        this.empty = true;
      } else {
        this.empty = false;

        // 课件信息and课件记录
        this.coursewares = coursewares.map((ele) => {
          // 签名照片和抓拍抓拍合并
          if (ele.coursewareLog) {
            ele.coursewareLog.pics = ele.coursewareLog.pics.length
              ? ele.coursewareLog.pics.split("_")
              : [];
            if (ele.coursewareLog.signPic) {
              ele.coursewareLog.pics.push(ele.coursewareLog.signPic);
            }

            ele.coursewareLog.zhuapaiDate = ele.coursewareLog.zhuapaiDate
              ? ele.coursewareLog.zhuapaiDate.split("_")
              : [];
            if (
              ele.coursewareLog.zhuapaiDate.length >
              ele.coursewareLog.pics.length
            )
              return this.$message.error("抓拍照片数据错误");

            let pics_items = [];

            ele.coursewareLog.pics_item = ele.coursewareLog.pics.map(
              (pic, index) => ({
                pic,
                picDate: ele.coursewareLog.zhuapaiDate[index],
              })
            );
            pics_items.push(ele.coursewareLog.pics_item);
            ele.coursewareLog.pics_item = pics_items;

            // 格式化课件记录

            let n = 6;
            let len = ele.coursewareLog.pics_item[0].length;
            let num = len % n === 0 ? len / n : Math.floor(len / n + 1);

            let res = [];

            if (num === 0) {
              let objs = [];

              for (let i = 0; i < n; i++) {
                objs.push("");
              }

              res.push(objs);
            } else {
              let objs = [];
              for (let i = 0; i < num; i++) {
                objs = ele.coursewareLog.pics_item[0].slice(i * n, i * n + n);
                res.push(objs);
              }

              res = res.map((obj) => {
                if (obj.length != n) {
                  let leng = obj.length;
                  for (let x = 0; x < n - leng; x++) {
                    obj.push("");
                  }
                }
                return obj;
              });
            }
            ele.coursewareLog.pics_item = res;
          }
          return ele;
        });
      }

      this.employee = employee ? employee : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  min-width: 1000px;
  .h1 {
    font-size: 20px;
    font-weight: 550;
    background-color: #ddd;
    padding: 20px 0 !important;
  }
  .h2 {
    font-size: 15px !important;
  }
  .h3 {
    background-color: #f7f7f7;
    font-weight: 550;
  }
  .text-left {
    text-align: left !important;
    align-items: flex-start !important;
  }

  .cu-table {
    border-top: 1px solid;
    border-left: 1px solid;

    .pic {
      width: 100%;
      height: 100px;
      margin: auto;
    }
  }
  .cu-tr {
    display: flex;
  }
  .cu-td {
    border-right: 1px solid;
    border-bottom: 1px solid;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .user-info {
    position: relative;

    .user-img {
      position: absolute;
      width: calc(100% / 7);
      height: 100%;
      top: 0;
      right: 0;
      border-right: 1px solid;
      border-bottom: 1px solid;
      box-sizing: border-box;
      box-sizing: border-box;
      background-color: #fff;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .grow1 {
    width: calc(100% / 7) !important;
  }
  .grow2 {
    width: calc(100% / 7 * 2) !important;
  }
  .grow6 {
    width: calc(100% / 7 * 6) !important;
  }

  .grow7 {
    width: calc(100% / 7 * 7) !important;
  }
}
</style>
