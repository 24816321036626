<template>
  <div class="employee-report">
    <div style="
        margin-bottom: 8px;
        font-size: 15px;
        font-weight: 550;
        color: #858585;
      ">
      员工列表查询
    </div>
    <el-form :inline="true" :model="employeeListDF" style="min-width: 800px">
      <el-form-item>
        <el-input v-model="employeeListDF.keyword" placeholder="请输入员工姓名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="employeeListDF.did" clearable placeholder="按部门筛选">
          <el-option v-for="item in departmentOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="employeeListDF.tid" clearable placeholder="按班组筛选" @focus="teamFocus">
          <el-option v-for="item in teamOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" plain @click="search">查询</el-button>
    </el-form>

    <div style="
        margin-bottom: 8px;
        font-size: 15px;
        font-weight: 550;
        color: #858585;
      ">
      报表类型选择
    </div>
    <el-form :inline="true">
      <el-form-item>
        <el-date-picker v-model="formDF.year" type="year" placeholder="选择年份查年报" value-format="yyyy">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-date-picker v-model="formDF.month" type="month" placeholder="选择月份查月报" value-format="yyyy-MM">
        </el-date-picker>
      </el-form-item>
    </el-form>

    <el-table :data="employeeList" border size="medium" v-loading="dataListLoading" style="width: 100%">
      <el-table-column type="index" width="60px" header-align="center" align="center" label="序号">
      </el-table-column>
      <el-table-column prop="departmentName" header-align="center" align="center" label="部门">
      </el-table-column>
      <el-table-column prop="tempName" header-align="center" align="center" label="班组">
      </el-table-column>
      <el-table-column prop="name" header-align="center" align="center" width="130" label="姓名">
      </el-table-column>
      <el-table-column prop="number" header-align="center" align="center" label="工号">
      </el-table-column>
      <el-table-column prop="gangwei" header-align="center" align="center" width="130" label="岗位">
      </el-table-column>

      <el-table-column prop="tel" header-align="center" align="center" label="联系电话">
      </el-table-column>
      <el-table-column prop="createDate" header-align="center" align="center" label="创建时间">
        <template slot-scope="scope">
          <div>
            {{scope.row.createDate? scope.row.createDate.split(' ')[0]:''}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160" header-align="center" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="btns" style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <el-button type="primary" size="small" @click="seeFormhandler(scope.row)">查看</el-button>
            <el-button type="success" size="small" @click="uploadFormhandler(scope.row)">下载</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination class="pagination" background layout="total, sizes, prev, pager, next, jumper" :total="totalCount"
      :current-page.sync="employeeListDF.page" :page-size.sync="employeeListDF.limit" @size-change="getEmployeeList"
      @current-change="getEmployeeList"></el-pagination>

    <EmployeeForm v-if="EmployeeFormVisible" ref="EmployeeForm" @refreshDataList="refreshDataList" />
  </div>
</template>

<script>
import EmployeeForm from "./form.vue";

export default {
  name: "employeeRp",
  components: {
    EmployeeForm,
  },
  data() {
    return {
      formDF: {
        year: "",
        month: "",
      }, // 请求参数（查看表单）
      departmentOptions: [], //部门列表
      teamOptions: [], // 班组列表
      year_or_month: "1",
      EmployeeFormVisible: true, // 查看员工表单列表显示状态
      status_opt: [
        { id: 0, label: "正常", value: 0 },
        { id: 1, label: "禁用", value: 1 },
        { id: 2, label: "未绑定", value: 2 },
        { id: 3, label: "已过期", value: 3 },
      ],
      dataList: [
        { id: 1, name: "jack" },
        { id: 2, name: "rose" },
      ],
      typeOptions: [
        { id: 0, label: "安全培训", value: 0 },
        { id: 1, label: "岗前培训", value: 1 },
        { id: 2, label: "违章培训", value: 2 },
      ],

      dataListLoading: false,

      // 员工
      employeeList: [],
      totalCount: 0,
      employeeListDF: {
        keyword: "",
        did: "",
        tid: "",
        limit: 10,
        page: 1,
      },

      // 岗位
      positionList: [],
      positionListDF: {
        cid: "",
        limit: 99,
        page: 1,
      },
    };
  },

  created() {
    this.getEmployeeList();
    this.getDepartmentList();
  },

  methods: {
    teamFocus() {
      this.teamOptions = [];
      if (!this.employeeListDF.did) {
        this.$message.error("请先选择部门");
        return;
      }

      this.$http.team.list({ limit: 99999, page: 1 }).then((data) => {
        if (data && data.code === 0) {
          if (data.data.list.length > 0) {
            data.data.list.forEach((item) => {
              this.teamOptions.push({
                value: item.id,
                label: item.name,
              });
            });
          }
        }
      });
    },
    // 获取部门列表
    getDepartmentList() {
      this.DepartmentOptions = [];
      this.$http.department.list({ limit: 99999, page: 1 }).then((data) => {
        if (data && data.code === 0) {
          if (data.data.list.length > 0) {
            data.data.list.forEach((item) => {
              this.departmentOptions.push({
                value: item.id,
                label: item.name,
              });
            });
          }
        }
      });
    },
    // 关闭弹窗重新获取数据
    refreshDataList() {
      this.getEmployeeList();
    },

    // 获取岗位列表
    getPositionList() {
      this.$api.position.list(this.positionListDF).then((res) => {
        if (res.code != 0 || !res.data || !res.data.list) return;

        this.positionList = res.data.list;
      });
    },

    // 获取员工列表
    getEmployeeList() {
      this.$http.employee.list(this.employeeListDF).then((res) => {
        if (res.code != 0 || !res.data || !res.data.list) return;

        let list = res.data.list;
        list = list.map((ele) => {
          ele.time = "";
          ele.selectPid = "";

          return ele;
        });

        this.employeeList = list;
        this.totalCount = res.data.totalCount;
      });
    },

    // 条件搜索（员工报表列表）
    search() {
      this.getEmployeeList();
    },
    // 选择员工拥有的岗位
    selectPosition(row) {
      if (!row.post) return [];

      let post = row.post.split("_");
      post = post.filter((ele) => {
        if (ele) {
          return ele;
        }
      });

      var filterObj = (obj) => {
        let result = this.positionList.filter((ele) => {
          return ele.id == obj;
        });

        return result[0];
      };

      post = post.map((ele) => {
        ele = filterObj(ele);
        return ele;
      });

      return post;
    },

    // 打开员工表单（废弃）
    openForm(row) {
      if (!row.time) return this.$message.warning("请先选择日期");

      this.$refs["Popover-" + row.id].showPopper = false;

      row.year = this.year_or_month == 1 ? row.time : "";
      row.month = this.year_or_month == 2 ? row.time : "";

      this.EmployeeFormVisible = true;
      this.$nextTick(() => {
        this.$refs.EmployeeForm.init(row);
      });
    },

    // 下载员工表单(废弃)
    upload(row) {
      if (!row.time) return this.$message.warning("请先选择日期");

      this.$refs["Popover-" + row.id].showPopper = false;

      let dataForm = {
        eid: row.id,
        postId: row.selectPid,
      };
      if (this.year_or_month == 1) {
        dataForm.year = row.time;
      } else if (this.year_or_month == 2) {
        dataForm.month = row.time;
      }
      if (row.tradeType) {
        dataForm.tradeType = row.tradeType;
      }
      let fileName = row.name + "+" + row.studentNumber + "+员工报表.xls";

      this.$api.employee.exportEmployeeInfo(dataForm, fileName).then((res) => {
        if (res.code != 0) return;

        console.log(res);
      });
    },
    // 查看表单
    seeFormhandler(row) {
      if (!this.formDF.year && !this.formDF.month)
        return this.$message.error("请先选择年份或月份");

      // 如果选择月份年份就变为空
      let formDF = { ...this.formDF };
      if (formDF.month) {
        formDF.year = "";
      }

      this.EmployeeFormVisible = true;
      this.$nextTick(() => {
        this.$refs.EmployeeForm.init(row, formDF);
      });
    },
    // 下载表单
    uploadFormhandler(row) {
      this.$message.info("功能正在紧急开发中");
      return;
      if (!this.formDF.year && !this.formDF.month)
        return this.$message.error("请先选择年份或月份");

      let dataForm = {
        eid: row.id,
        ...this.formDF,
      };
      if (dataForm.month) {
        dataForm.year = "";
      }

      let fileName = row.name + "+" + row.studentNumber + "+员工报表.xls";

      this.$http.employee.exportEmployeeInfo(dataForm, fileName).then((res) => {
        if (res.code != 0) return;
      });
    },
  },
};
</script>

<style lang="scss">
.employee-report {
  padding: 20px;

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0;
  }
}
</style>
